import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import SidebarArr from './SidebarArr'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { dispatchResetApiRequestQQ } from '../store/actions/userActions'
const Sidebar = ({ toggleSideNav, setToggleSideNav }) => {
  const location = useLocation()
  const sideBarData = SidebarArr
  const [activeItem, setActiveItem] = useState(false)

  const home=()=>{

    setToggleSideNav(!toggleSideNav)

    // dispatchResetApiRequestQQ()

  }

  return (
    <>
      <aside
        className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start m-1 ${
          toggleSideNav ? 'sideOpen' : ''
        }`}
        id='sidenav-main'
      >
        <div className='sidenav-header'>
          <i
            className='fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-xl-none'
            aria-hidden='true'
            id='iconSidenav'
          ></i>
          {/* <Link to=''> */}
            <img 
            onClick={() => home()}
            src='/assets/img/logo1.png' width='100px' alt='' />
          {/* </Link> */}
        </div>
        <hr className='horizontal dark mt-0' />
        <div
          className='collapse navbar-collapse w-auto max-height-vh-100 h-100'
          id='sidenav-collapse-main'
        >
          <ul className='navbar-nav'>
            {sideBarData?.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`nav-item ${
                    item.pathname.some(path => location.pathname.includes(path))
                      ? 'active'
                      : ''
                  }`}
                >
                  <div
                    onClick={() => setActiveItem(!activeItem)}
                    className='nav-link'
                    style={{ cursor: 'pointer' }}
                  >
                    <div className='icon icon-sm shadow border-radius-md text-center me-2 d-flex align-items-center justify-content-center'>
                      {item?.icon}
                    </div>
                    <span className='nav-link-text ms-1'>
                      {item?.name} <IoIosArrowDropdownCircle />
                    </span>
                  </div>
                  {activeItem && (
                    <ul className='sub_items'>
                      {item?.sub &&
                        item?.sub?.map((item1, subIndex) => {
                          return (
                            <li
                              key={subIndex}
                              className={`nav-item ${
                                item1.pathname.some(path =>
                                  location.pathname.includes(path)
                                )
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => {
                                setActiveItem(activeItem)
                              }}
                            >
                              <Link to={item1?.path} className='nav-link'>
                                <div className='icon icon-sm shadow border-radius-md text-center me-2 d-flex align-items-center justify-content-center'>
                                  {item1?.icon}
                                </div>
                                <span className='nav-link-text ms-1'>
                                  {item1?.name}
                                </span>
                              </Link>
                            </li>
                          )
                        })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </aside>
    </>
  )
}

export default Sidebar

