import './App.css'
import AllRoutes from './routes/routes'

function App () {
  return (
    <div className='App'>
      <AllRoutes />
      
    </div>
  )
}

export default App
