import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../common/Layout'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
// import ReactSelect from '../ReactSelect/ReactSelect'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { useDispatch, useSelector } from 'react-redux'
import {
  dispatchMmvData,
  dispatchResetApiRequestQQ
} from '../../store/actions/userActions'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'
import { useForm } from 'react-hook-form'

const Mmv = () => {
  const dispatch = useDispatch()
  const apiUrl = 'https://api.insurefast.in/api/v1/'
  const mmvData = useSelector(state => state?.root?.mmvData)

  const [resetAction, setResetAction] = useState(false)

  const [action, setAction] = useState(false)
  const [model, setModel] = useState(false)

  // debugger
  const URL_INSFST = 'https://api.insurefast.in/api/v1/'
  const URL_RETRO = ''
  const URL_EXPERT = ''

  const [insurerData, setInsurerData] = useState([])
  const [vehicleType, setVehicleType] = useState('')
  const [insurer, setInsurer] = useState('')
  const [partnerMmvData, setPartnerMmvData] = useState([])
  const [insurerMmvData, setInsurerMmvData] = useState([])
  const [makeData, setMakeData] = useState([])
  const [modelData, setModelData] = useState([])
  const [varientData, setVarientData] = useState([])
  const [fuelType, setFuelType] = useState([])
  const [selectedFuelType, setSelectedFuelType] = useState('')
  const [makeId, setMakeId] = useState('')
  const [modelId, setModelId] = useState('')
  const [filter, setFilter] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [selectedMake, setSelectedMake] = useState(null)
  const [selectedModal, setSelectedModal] = useState(null)
  const [selectedVarient, setSelectedVarient] = useState(null)
  const [errors, setErrors] = useState({})
  const [status, setStatus] = useState()
  const [currentItemsData, setCurrentItemsData] = useState(false)

  // Pagination Start
  const [pageCount, setPageCount] = useState()
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [itemOffset, setItemOffset] = useState(0)

  const handlePageClick = e => {
    const newOffset = e.selected * itemsPerPage
    setItemOffset(newOffset)
  }

  const endOffset = itemOffset + itemsPerPage
  const currentItems =
    partnerMmvData && partnerMmvData.slice(itemOffset, endOffset)
  const handleChangeItemPerPage = e => {
    setItemsPerPage(e)
  }

  useEffect(() => {
    currentItems.map(item => {
      const value = insurerMmvData.find(data => item?.bajaj === data?.value)
      console.log('value', value)
    })
  }, [currentItems])

  // Pagination End

  useEffect(() => {
    dispatchMmvData('apiUrl', apiUrl)
    // if (mmvData?.partnerName === 'INSFST') {
    //   // setLoading(true)
    //   GetDataWithToken(mmvData?.apiUrl, 'admin/getAllInsData').then(res => {
    //     setLoading(false)
    //     if (res?.status === 'success') {
    //       setInsurerData(res?.data)
    //     } else {
    //       console.log('Insurer Data', res?.status)
    //     }
    //   })
    // }
    handleSelectProduct()
  }, [])

  // Select Product
  const handleSelectProduct = val => {
    setLoading(true)
    // if (val === 'INSFST') {
    //   // dispatchMmvData('apiUrl', URL_INSFST)
    //   dispatchMmvData('partnerName', val)
    // } else if (val === 'RETRO') {
    //   dispatchMmvData('apiUrl', URL_RETRO)
    //   dispatchMmvData('partnerName', val)
    // } else if (val === 'EXPERT') {
    //   dispatchMmvData('apiUrl', URL_EXPERT)
    //   dispatchMmvData('partnerName', val)
    // } else {
    //   dispatchMmvData('apiUrl', '')
    // }
    GetDataWithToken(mmvData?.apiUrl, 'admin/getAllInsData').then(res => {
      setLoading(false)
      if (res?.status === 'success') {
        setInsurerData(res?.data)
      } else {
        console.log('Insurer Data', res?.status)
      }
    })
  }

  // const handleSelectInsurer = (val, insurerData) => {
  //   setInsurer(val)
  //   dispatchMmvData('insurerName', val)
  //   const a = insurerData.filter(x => x.insurerName === val)
  //   const id = a[0].insurerId
  //   dispatchMmvData('insurerId', id)
  // }
  const handleSelectInsurer = (val, insurerData) => {
    setVehicleType('')
    dispatchMmvData('vehicleType', '')
    setSelectedMake(null)
    setSelectedModal(null)
    setSelectedVarient(null)
    setFuelType(null)
    dispatchMmvData('fuelType', '')
    setSelectedFuelType('')
    setVariantIdInput(null)
    dispatchMmvData('makeId', '')
    dispatchMmvData('modelId', '')              
    dispatchMmvData('varientId', '')
    dispatchMmvData('action', '')
    setInsurer(val)
    setCurrentItemsData(false)
    dispatchMmvData('insurerName', val)
    const selectedInsurer = insurerData.find(x => x.insurerName === val)
    const id = selectedInsurer ? selectedInsurer.insurerId : ''
    dispatchMmvData('insurerId', id)
  }

  const handleSelectVehicleType = val => {
    setSelectedMake(null)
    setSelectedModal(null)
    setSelectedVarient(null)
    setFuelType(null)
    dispatchMmvData('fuelType', '')
    setSelectedFuelType('')
    setVariantIdInput(null)
    dispatchMmvData('makeId', '')
    dispatchMmvData('modelId', '')              
    dispatchMmvData('varientId', '')
    dispatchMmvData('action', '')
    setCurrentItemsData(false)
    setVehicleType(val)
    dispatchMmvData('vehicleType', val)
  }

  // Get MMV Data of Insurer and Partner
  const getInsurerMmv = () => {
    setLoading(true)
    let insurerObj = {
      insurerId: mmvData?.insurerId,
      insurerName: mmvData?.insurerName,
      vehicleType: mmvData?.vehicleType,
      mmvFullName: ''
    }
    PostDataWithToken(
      mmvData?.apiUrl,
      'admin/getInsurerMmvData',
      insurerObj
    ).then(res => {
      let data = res?.data
      let i = 0
      let arr = []
      while (i < data?.length) {
        let item = data[i]
        arr.push({
          label: item?.mmvFullName,
          value: item?.id
        })
        i++
      }
      setInsurerMmvData(arr)
      setLoading(false)
    })
  }
  const resetPartnerMmv = () => {    
    setLoading(true)
    setSelectedMake(null)
    setSelectedModal(null)
    setSelectedVarient(null)
    setFuelType(null)
    setStatus('')
    setSelectedFuelType('')
    setVariantIdInput(null)
    dispatchMmvData('makeId', '')
    dispatchMmvData('modelId', '')
    dispatchMmvData('fuelType', '')
    dispatchMmvData('varientId', '')
    dispatchMmvData('action', '')
    getPartnerMMV('', '', '', '')
  }
  // Get Partner MMV
  const getPartnerMMV = (val1, val2, val3, val4) => {
    setLoading(true)
    let modelVal = model ? val2 : ''
    let partnerObj = {
      makeId: val1 === mmvData?.makeId ? mmvData?.makeId : val1,
      mmvFullName: '',
      vehicleType: mmvData?.vehicleType,
      modelId: modelVal === mmvData?.modelId ? mmvData?.modelId : modelVal,
      variantId: val3 !== '' ? val3 : variantIdInput,
      fuelType: selectedFuelType || '',
      insurerId: mmvData?.insurerId || '',
      action: val4 !== '' ? val4 : ''
    }
    if (!variantIdInput) {
      partnerObj = {
        ...partnerObj
      }
    }

    PostDataWithToken(mmvData?.apiUrl, 'admin/getMmvData', partnerObj).then(
      res => {
        setPartnerMmvData(res?.data)
        setPageCount(Math.ceil(res?.data.length / itemsPerPage))
        const fuelTypes = res?.data.map(item => item?.fuelType)
        const uniqueFuelTypes = [...new Set(fuelTypes)]
        partnerObj.fuelType = uniqueFuelTypes.join(', ')
        setFuelType(partnerObj.fuelType)
        setLoading(false)
      }
    )
    setModel(true)
  }
  // Get Make Data
  const getMakeData = val => {
    setLoading(true)
    let makeObj = {
      vehicleType: mmvData?.vehicleType
    }
    PostDataWithToken(mmvData?.apiUrl, 'admin/getAllMakeData', makeObj).then(
      res => {
        let data = res?.data
        let i = 0
        let arr = []
        while (i < data?.length) {
          let item = data[i]
          arr.push({
            label: item?.make,
            value: item?.makeId
          })
          i++
        }
        setMakeData(arr)
        setLoading(false)
      }
    )
  }
  // const handleSubmit = () => {
  //   setLoading(true)
  //   getInsurerMmv()
  //   getPartnerMMV()
  //   getMakeData()
  // }
  const validateForm = () => {
    const newErrors = {}
    if (!insurer) newErrors.insurer = 'Please select an insurer.'
    if (!vehicleType) newErrors.vehicleType = 'Please select a vehicle type.'
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (!validateForm()) return
    setCurrentItemsData(true)
    setLoading(true)
    getInsurerMmv()
    getPartnerMMV()
    getMakeData()
  }

  const handleSelectInsurerMmvId = (selectedOption, itemId) => {
    dispatchMmvData('insurerMmvId', selectedOption?.value)
    setSelectedValue(prevSelected => ({
      ...prevSelected,
      [itemId]: selectedOption
    }))
  }
  // const handleSelectInsurerMmvId = (item) => {
  //   console.log("handleSelectInsurerMmvId", item);
  //   dispatchMmvData("insurerMmvId", item?.value);
  // };

  // useEffect(() => {
  //   // console.log('selectedOption', selectedValue)
  // }, [selectedValue])

  const resetData = ()=>{
    dispatchMmvData('fuelType', '')

    setSelectedVarient(null)
    dispatchMmvData('varientId', '')
    dispatchMmvData('modelId', '')
    setSelectedFuelType(null)
    setSelectedModal(null)
    setFuelType(null)
  }
  const handleSelectMake = (val, selectedOption) => {
    resetData()
    setMakeId(val)
    setSelectedMake(selectedOption)
    dispatchMmvData('makeId', val)
    resetAction === false
      ? getPartnerMMV(val, '', '', '')
      : getPartnerMMV(val, '', '', mmvData?.action)
    setResetAction(true)
  }

  const handleSelectModel = (val, selectedOption) => {
    setModelId(val)
    setFuelType(null)
    setSelectedVarient(null)
    setSelectedModal(selectedOption)
    dispatchMmvData('modelId', val)
    let actionVal = action ? mmvData?.action : ''
    getPartnerMMV(mmvData?.makeId, val, '', actionVal)
  }

  const handleSelectVarient = (val, selectedOption) => {
    setSelectedVarient(selectedOption)
    dispatchMmvData('varientId', val)
    getPartnerMMV(mmvData?.makeId, mmvData?.modelId, val, '')
  }

  const handleSelectMapData = val => {
    dispatchMmvData('action', val)
    setAction(true)
  }
  // Get Modal Data
  const getModelData = val => {
    setModelData([])
    setVarientData([])

    dispatchMmvData('makeId', val)
    setLoading(true)
    let makeObj = {
      vehicleType: mmvData?.vehicleType,
      makeId: val
    }
    PostDataWithToken(mmvData?.apiUrl, 'admin/getAllModelData', makeObj).then(
      res => {
        let data = res?.data
        let i = 0
        let arr = []
        while (i < data?.length) {
          let item = data[i]
          arr.push({
            label: item?.model,
            value: item?.modelId
          })
          i++
        }
        setModelData(arr)
        setLoading(false)
      }
    )
  }
  //Get Variant Data
  const getVariantData = val => {
    setLoading(true)
    dispatchMmvData('modelId', val)
    let makeObj = {
      vehicleType: mmvData?.vehicleType,
      makeId: makeId ? makeId : mmvData?.makeId,
      modelId: val
    }
    PostDataWithToken(mmvData?.apiUrl, 'admin/getAllVariantData', makeObj).then(
      res => {
        let data = res?.data
        let i = 0
        let arr = []
        while (i < data?.length) {
          let item = data[i]
          arr.push({
            label: item?.variant,
            value: item?.variantId
          })
          i++
        }
        setVarientData(arr)
        setLoading(false)
      }
    )
  }
  // mapping data
  const handleMapping = (item, matchingMmv) => {
    setLoading(true)
    let mapObj = {
      vehicleType: mmvData?.vehicleType,
      insurerMmvId: mmvData?.insurerMmvId
        ? mmvData?.insurerMmvId
        : matchingMmv?.value,
      insurerId: mmvData?.insurerId,
      insFastMmvId: item,
      action: 'mapped'
    }
    PostDataWithToken(mmvData?.apiUrl, 'admin/mapMmvData', mapObj).then(res => {
      setLoading(false)
      if (res?.status === 'success') {
        toast.success('MMV Code Mapped!!')
      } else {
        toast.error('Something went wrong')
      }
    })
  }
  //unmap data
  const handleUnMapping = item => {
    setLoading(true)
    let mapObj = {
      vehicleType: mmvData?.vehicleType,
      insurerMmvId: '',
      insurerId: mmvData?.insurerId,
      insFastMmvId: item,
      action: 'Unmapped'
    }
    PostDataWithToken(mmvData?.apiUrl, 'admin/mapMmvData', mapObj).then(res => {
      setLoading(false)
      if (res?.status === 'success') {
        toast.success('MMV Code UnMapped!!')
      } else {
        toast.error('Something went wrong')
      }
    })
  }
  //  Reset Filter Data

  const handleFuelType = val => {
    dispatchMmvData('fuelType', val)
    setSelectedFuelType(val)
  }
  const handleFilter = () => {
    setFilter(!filter)
  }

  const [variantIdInput, setVariantIdInput] = useState('')
  const handleVariantIdChange = e => {
    setVariantIdInput(e.target.value)
  }
  const [searchType, setSearchType] = useState('variantId')
  const handleSearch = () => {
    if (searchType === 'variantId') {
      getPartnerMMV('', '', variantIdInput, '')
      setSelectedMake(null)
      setSelectedModal(null)
      setFuelType(null)
      setSelectedVarient(null)
    } else if (searchType === 'makeId') {
      getPartnerMMV(makeId || mmvData?.makeId)
    } else if (searchType === 'modelId') {
      getPartnerMMV(makeId || mmvData?.modelId)
    }
  }
  // text copy
 const textRef = useRef(null);

  const handleCopyText = () => {
    if (!textRef.current) return;

    const textToCopy = textRef.current.innerText;

    // Use the Clipboard API if available
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success('Copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
          toast.error('Failed to copy text!');
        });
    } else {
      // Fallback for older browsers
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy');
        toast.success('Copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy text: ', err);
        toast.error('Failed to copy text!');
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
  return (
    <Layout>
      <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          {/* <div className='col-12'> */}
          <div className='card mb-4'>
            <div className='card-body pt-0 pb-2'>
              <div className='row mt-2'>
                {/* <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Product</label>
                    <div className='mb-3'>
                      <select
                        className='form-control'
                        onChange={e => handleSelectProduct(e?.target?.value)}
                        // defaultValue={mmvData?.partnerName || ""}
                        // value={mmvData.partnerName}
                      >
                        <option key='' value=''>
                          Select Product
                        </option>
                        <option
                          key='INSFST'
                          selected={
                            mmvData?.partnerName === 'INSFST' ? true : false
                          }
                          value='INSFST'
                        >
                          Insurefast
                        </option>
                      </select>
                    </div>
                  </div> */}

                {/* <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Insurer</label>
                    <div className='mb-3'>
                      <select
                        className='form-control'
                        onChange={e =>
                          handleSelectInsurer(e.target.value, insurerData)
                        }
                        value={insurerData.insurerName}
                      >
                        <option key='' value=''>
                          Select Insurance
                        </option>
                        {insurerData?.map((item, index) => {
                          return (
                            <option
                              key={index}
                              selected={
                                insurerData
                                  ? insurerData?.find(insurer => {
                                      if (
                                        insurer?.insurerId &&
                                        insurerData?.insurerName
                                      ) {
                                        return (
                                          parseInt(insurerData?.insurerName) ===
                                          insurer?.insurerId
                                        )
                                      }
                                      return false 
                                    })?.mappingName
                                  : false
                              }
                              value={item?.insurerName}
                            >
                              {item?.mappingName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>

                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Vechicle Type</label>
                    <div className='mb-3'>
                      <select
                        className='form-control'
                        onChange={e => handleSelectVehicleType(e.target.value)}
                        value={mmvData.vehicleType}
                        name='vehicleType'
                        id='vehicleType'
                      >
                        <option key='' value=''>
                          Select Vechicle
                        </option>
                        <option
                          key='2w'
                          value='2w'
                          selected={
                            mmvData?.vehicleType === '2w' ? true : false
                          }
                        >
                          Two Wheeler
                        </option>
                        <option
                          key='4w'
                          value='4w'
                          selected={
                            mmvData?.vehicleType === '4w' ? true : false
                          }
                        >
                          Private Car
                        </option>
                        <option
                          key='pcv'
                          value='pcv'
                          selected={
                            mmvData?.vehicleType === 'pcv' ? true : false
                          }
                        >
                          Passanger Carrying Vehicle
                        </option>
                        <option
                          key='gcv'
                          value='gcv'
                          selected={
                            mmvData?.vehicleType === 'gcv' ? true : false
                          }
                        >
                          Goods Carrying Vehicle
                        </option>
                        <option
                          key='misc'
                          value='misc'
                          selected={
                            mmvData?.vehicleType === 'misc' ? true : false
                          }
                        >
                          Miscelleneous Vehicle
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label></label>
                    <div className='mb-3 d-flex justify-content-end gap-3'>
                      <button
                        type='button'
                        class='btn btn-sec'
                        onClick={() => handleSubmit()}
                        disabled={loading}
                      >
                        
                        submit
                      </button>
                    </div>
                  </div> */}
                <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                  <label>Insurer</label>
                  <div className='mb-3'>
                    <select
                      className='form-control'
                      onChange={e =>
                        handleSelectInsurer(e.target.value, insurerData)
                      }
                      value={insurer}
                    >
                      <option value=''>Select Insurance</option>
                      {insurerData?.map((item, index) => (
                        <option key={index} value={item?.insurerName}>
                          {item?.mappingName}
                        </option>
                      ))}
                    </select>
                    {errors.insurer && (
                      <div className='text-danger'>{errors.insurer}</div>
                    )}
                  </div>
                </div>

                <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                  <label>Vehicle Type</label>
                  <div className='mb-3'>
                    <select
                      className='form-control'
                      onChange={e => handleSelectVehicleType(e.target.value)}
                      value={vehicleType}
                      name='vehicleType'
                      id='vehicleType'
                    >
                      <option value=''>Select Vehicle</option>
                      <option value='2w'>Two Wheeler</option>
                      <option value='4w'>Private Car</option>
                      <option value='pcv'>Passenger Carrying Vehicle</option>
                      <option value='gcv'>Goods Carrying Vehicle</option>
                      <option value='misc'>Miscellaneous Vehicle</option>
                    </select>
                    {errors.vehicleType && (
                      <div className='text-danger'>{errors.vehicleType}</div>
                    )}
                  </div>
                </div>

                <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                  <label></label>
                  <div className='mb-3 d-flex justify-content-end gap-3'>
                    <button
                      type='button'
                      className='btn btn-sec'
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? 'Loading...' : 'Submit'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {currentItemsData ? (
            <>
              <div className='card mb-4 pt-2 pb-2'>
                <div onClick={handleFilter}>
                  <button
                    className={filter ? 'filter-btn active' : 'filter-btn'}
                  >
                    {' '}
                    Show Filter
                  </button>
                </div>
                {filter && (
                  <div className='card-body pt-0 pb-2'>
                    <div className='row'>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>Make</label>
                        <ReactSelect
                          options={makeData}
                          name='Make'
                          placeholder='Select make'
                          value={selectedMake}
                          onChange={e => {
                            handleSelectMake(e?.value)
                            getModelData(e?.value)
                          }}
                        />
                      </div>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>Model</label>
                        <ReactSelect
                          options={modelData}
                          name='Model'
                          placeholder='Select model'
                          value={selectedModal}
                          onChange={e => {
                            handleSelectModel(e?.value)
                            getVariantData(e?.value)
                          }}
                        />
                      </div>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>Fuel Type</label>
                        <div className='mb-3'>
                          <div className='mb-3'>
                            <select
                              className='form-control'
                              id='fuelSelect'
                              name='fuelSelect'
                              onChange={e => handleFuelType(e.target.value)}
                            >
                              <option value=''>Select fuel</option>
                              {typeof fuelType === 'string' &&
                                fuelType.split(', ').map((type, index) => (
                                  <option key={index} value={type}>
                                    {type}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>Varient</label>
                        <ReactSelect
                          options={varientData}
                          name='Model'
                          placeholder='Select Varient'
                          value={selectedVarient}
                          onChange={e => handleSelectVarient(e?.value)}
                        />
                      </div>
                      <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                        <label>Status</label>
                        <div className='mb-3'>
                          <select
                            className='form-control'
                            name=''
                            id=''
                            // value={status}
                            onChange={e => {
                              handleSelectMapData(e.target.value)
                              getPartnerMMV(
                                mmvData.makeId,
                                mmvData.modelId,
                                mmvData.varientId,
                                e.target.value
                              )
                            }}
                          >
                            <option key='' value=''>
                              All
                            </option>
                            <option key='mapped' value='mapped'>
                              Mapped
                            </option>
                            <option key='unmapped' value='unmapped'>
                              Unmapped
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className='col-xl-9 col-lg-3 col-md-6 col-12 mt-4 d-flex justify-content-end align-items-center'>
                        <button
                          type='button'
                          className='btn btn-danger'
                          onClick={() => resetPartnerMmv()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* </div> */}
              {loading ? (
                <>
                  <div className='loading'>
                    <div className='spinner'></div>
                    <p>Loading...</p>
                  </div>
                </>
              ) : (
                <>
                  {currentItems?.length > 0 ? (
                    <div className='col-12'>
                      <div className='card mb-4'>
                        <div className='card-body pt-0 pb-2'>
                          <div className='row'>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12 d-flex align-items-center gao-1'>
                              <div>
                                <label>Variant ID</label>
                                <div className='mb-3'>
                                  <input
                                    type='search'
                                    placeholder='Variant ID'
                                    className='form-control'
                                    value={variantIdInput}
                                    onChange={handleVariantIdChange}
                                  />
                                </div>
                              </div>
                              <div
                                className='mt-3 secrch-btn'
                                onClick={() => handleSearch()}
                              >
                                <button>search</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-body pt-0 pb-2'>
                          <div className='table-responsive p-0'>
                            <table className='table align-items-center mb-0'>
                              <thead>
                                <tr>
                                  <th className='text-uppercase text-xxs font-weight-bolder text-dark'>
                                    Variant Id
                                  </th>
                                  <th className='text-uppercase text-xxs font-weight-bolder text-dark'>
                                    Product Mmv
                                  </th>
                                  <th className='text-uppercase text-xxs font-weight-bolder text-dark'>
                                    Insurer
                                  </th>
                                  <th
                                    className='text-uppercase  text-xxs font-weight-bolder fs-4'
                                    style={{ color: '#000' }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems.map((item, index) => {
                                  // let insName = mmvData?.insurerName
                                  // const insurerMapping = {
                                  //   'Kotak Mahindra General Insurance Company Limited': item.kotak,
                                  //   'Bajaj Allianz General Insurance Company Limited': item.bajaj,
                                  //   'Go Digit General Insurance Limited': item.digit,
                                  // };
                                  // const insurerName = insurerMapping[insName] || '';
                                  // const matchingMmv = insurerMmvData.find(
                                  //   data => data.value === insurerName
                                  // );
                                  let insName = mmvData?.insurerName
                                  let insurerName
                                  if (
                                    insName ===
                                    'Kotak Mahindra General Insurance Company Limited'
                                  ) {
                                    insurerName = item.kotak
                                  } else if (
                                    insName === 'Bajaj General Insurance'
                                  ) {
                                    insurerName = item.bajaj
                                  } else if (
                                    insName ===
                                    'Go Digit General Insurance Limited'
                                  ) {
                                    insurerName = item.digit
                                  } else {
                                    insurerName = item.bajaj
                                  }

                                  const matchingMmv = insurerMmvData.find(
                                    data => data.value === insurerName
                                  )
                                  return (
                                    <tr key={index}>
                                      <td
                                        className='select_variant'
                                        ref={textRef}
                                        onClick={handleCopyText}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {item?.variantId}
                                      </td>
                                      <td className='select_partner'>
                                        {item?.mmvFullName}
                                      </td>
                                      <td style={{ width: '45%' }}>
                                        <div className='mmv_select'>
                                          <ReactSelect
                                            options={insurerMmvData}
                                            name='MMV'
                                            value={
                                              selectedValue[item.id] ||
                                              matchingMmv ||
                                              null
                                            }
                                            placeholder='Select MMV'
                                            onChange={selectedOption =>
                                              handleSelectInsurerMmvId(
                                                selectedOption,
                                                item.id
                                              )
                                            }
                                            className='select_ins'
                                            menuPortalTarget={document.body}
                                            // components={{ MenuList }}
                                            styles={{
                                              menuPortal: base => ({
                                                ...base,
                                                zIndex: 9999
                                              })
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex gap-2'>
                                          <button
                                            type='button'
                                            className='btn btn-sec'
                                            onClick={() =>
                                              handleMapping(
                                                item?.id,
                                                matchingMmv
                                              )
                                            }
                                          >
                                            {/* {loading ? <Loader /> : 'Map'} */}
                                            map
                                          </button>
                                          <button
                                            type='button'
                                            className='btn suc-btn'
                                            onClick={() =>
                                              handleUnMapping(item?.id)
                                            }
                                          >
                                            {/* {loading ? <Loader /> : 'Unmap'} */}
                                            Unmap
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='card mb-4 p-3'>
                        <div className='d-flex align-items-center justify-content-between flex-wrap'>
                          <div>
                            <p>
                              Showing 1 - {itemsPerPage} items of{' '}
                              {partnerMmvData?.length}
                            </p>
                          </div>
                          <div className='main-page-item'>
                            <div className='show-page'>
                              <select
                                className='form-control'
                                onClick={e =>
                                  handleChangeItemPerPage(e?.target?.value)
                                }
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                            <div>
                              <ReactPaginate
                                breakLabel='...'
                                nextLabel='>'
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel='<'
                                renderOnZeroPageCount={null}
                                pageClassName='page-item'
                                pageLinkClassName='page-link'
                                previousClassName='page-item'
                                previousLinkClassName='page-link'
                                nextClassName='page-item'
                                nextLinkClassName='page-link'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                containerClassName='pagination'
                                activeClassName='active'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p style={{ textAlign: 'center', fontSize: '1rem' }}>
                      {' '}
                      No Record Found
                    </p>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <p style={{ textAlign: 'center', fontSize: '1rem' }}>
                {' '}
                No Record Found
              </p>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Mmv
