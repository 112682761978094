import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/Auth/Login'
import Dashboard from '../pages/Dashboard/Dashboard'
import Profile from '../common/Profile'
import CheckAuth from './CheckAuth'
import Mmv from '../pages/MMV/Mmv'
import ForgotPassword from '../pages/Auth/ForgotPassword'
import CreatePassword from '../pages/Auth/CreatePassword'
import Users from '../pages/Users/users'
import AddUser from '../pages/Users/AddUser'
import Rto from '../pages/RTO/Rto'

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />{' '}
      <Route path='/forget' element={<ForgotPassword />} />{' '}
      <Route path='/create' element={<CreatePassword />} />{' '}
      <Route element={<CheckAuth />}>
        <Route path='/dashboard' element={<Dashboard />} />
        
        {/* Mapping*/}
        <Route path='/mmv' element={<Mmv />} />
        <Route path='/rto' element={<Rto />} />
        <Route path='/users' element={<Users />} />
        <Route path='/add-user' element={<AddUser />} />

        {/* Profile */}
        <Route path='/profile' element={<Profile />} />
      </Route>
    </Routes>
  )
}

export default AllRoutes
