import React, { useEffect, useState } from 'react'
import Loginfooter from '../../common/Loginfooter'
import { useForm } from 'react-hook-form'
import { PostDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router'
import Loader from '../../common/Loader'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchMmvData } from '../../store/actions/userActions'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const ForgetUrl = 'https://api.insurefast.in/api/v1/'
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showFields, setShowFields] = useState(false); // State to track whether to show additional fields
  const cookie = useCookies()
  const [cookies, setCookie, removeCookie] = useCookies([
    'userName',
    'token',
  ])
  useEffect(()=>{
    dispatchMmvData('apiUrl',ForgetUrl)
  })


  const handleForget = data => {
    setLoading(true);
    PostDataWithToken(ForgetUrl, '/admin/forgotPassword', data)
      .then(res => {
        if (res && res.status === 'success') {
          const { token, username, userType } = res.data;
          setCookie('token', token, { path: '/' });
          setCookie('username', username, { path: '/' });
          setShowFields(true);
         
        } else {
          console.error('Login failed:', res);
        }
      })
      .catch(error => {
        console.error('Login error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetSubmit = data => {
    setLoading(true);
    PostDataWithToken(ForgetUrl, 'admin/resetPassword', data)
      .then(res => {
        if (res && res.status === 'success') {
          const { token, username, userType } = res.data;
          setCookie('token', token, { path: '/' });
          setCookie('username', username, { path: '/' });
          setShowFields(true);
          navigate('/');
        } else {
          console.error('Password reset failed:', res);
        }
      })
      .catch(error => {
        console.error('Password reset error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {/* <LoginHeader /> */}
      <main className='logout  mt-0 ms-0' style={{ overflow: 'hidden' }}>
        <section>
          <div className='page-header min-vh-75'>
            <div className='container'>
              <div className='row'>
                <div className='col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto'>
                  <div className='card card-plain mt-4'>
                    <div className='card-header pb-0 text-left bg-transparent'>
                      <Link to=''>
                        <img
                          src='/assets/img/logo1.png'
                          style={{ maxWidth: '120px' }}
                          alt=''
                        />
                      </Link>
                      <h3 className='font-weight-bolder text-info text-gradient'>
                        Welcome back
                      </h3>
                      <p className='mb-0'>
                        Enter your username 
                      </p>
                    </div>

                    <div className='card-body'>
                      <form role='form' onSubmit={handleSubmit(handleForget)}>
                        <label>Username</label>
                        <div className='mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Username'
                            aria-label='Username'
                            aria-describedby='email-addon'
                            id='username'
                            name='username'
                            {...register('username', {
                              required: 'Username is required'
                            })}
                          />
                          <span>
                            <p className='text-danger error-msg'>
                              {errors.username && errors?.username?.message}
                            </p>
                          </span>
                        </div>
                        {showFields && (
                          <>
                            <label>OTP</label>
                            <div className='mb-3'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='OTP'
                                aria-label='OTP'
                                aria-describedby='otp-addon'
                                id='otp'
                                name='otp'
                                {...register('otp', {
                                  required: 'OTP is required'
                                })}
                              />
                              <span>
                                <p className='text-danger error-msg'>
                                  {errors.otp && errors?.otp?.message}
                                </p>
                              </span>
                            </div>
                            <label>New Password</label>
                            <div className='mb-3'>
                              <input
                                type='password'
                                className='form-control'
                                placeholder='New Password'
                                aria-label='New Password'
                                aria-describedby='password-addon'
                                id='newPassword'
                                name='newPassword'
                                {...register('newPassword', {
                                  required: 'New Password is required'
                                })}
                              />
                              <span>
                                <p className='text-danger error-msg'>
                                  {errors.newPassword && errors?.newPassword?.message}
                                </p>
                              </span>
                            </div>
                            <label>Confirm Password</label>
                            <div className='mb-3'>
                              <input
                                type='password'
                                className='form-control'
                                placeholder='Confirm Password'
                                aria-label='Confirm Password'
                                aria-describedby='confirm-password-addon'
                                id='confirmPassword'
                                name='confirmPassword'
                                {...register('confirmPassword', {
                                  required: 'Confirm Password is required'
                                })}
                              />
                              <span>
                                <p className='text-danger error-msg'>
                                  {errors.confirmPassword && errors?.confirmPassword?.message}
                                </p>
                              </span>
                            </div>
                            <div className='text-center'>
                              <button
                                type='submit'
                                className='btn bg-gradient-info w-100 mt-4 mb-0'
                                onClick={handleSubmit(handleResetSubmit)}
                              >
                                {loading ? <Loader /> : 'Submit'}
                              </button>
                            </div>
                          </>
                        )}
                        {!showFields && (
                          <div className='text-center'>
                            <button
                              type='submit'
                              className='btn bg-gradient-info w-100 mt-4 mb-0'
                            >
                              {loading ? <Loader /> : 'Submit'}
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='oblique position-absolute top-0 h-100 d-md-block d-none me-n8'>
                    <div className='oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Loginfooter />
      </main>
    </div>
  )
}

export default ForgotPassword
