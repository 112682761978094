import React, { useEffect, useState } from 'react'
import Loginfooter from '../../common/Loginfooter'
import { useForm } from 'react-hook-form'
import { PostDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router'
import Loader from '../../common/Loader'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { dispatchMmvData, dispatchResetApiRequestQQ, saveUserDetails } from '../../store/actions/userActions'
import { toast } from 'react-toastify'

const Login = () => {
  const dispatch = useDispatch()
  const loginUrl = 'https://api.insurefast.in/api/v1/'
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const cookie = useCookies()
  const [cookies, setCookie] = useCookies([
    'userName',
    'token',
  ])
  useEffect(()=>{
    dispatchMmvData('apiUrl',loginUrl)
  })

  const handleLogin = (data) => {
    setLoading(true);  
    PostDataWithToken(loginUrl, 'admin/loginPanel', data)
      .then((res) => {
        if (res && res.status === 'success') {
          const user = res.data.user;
          setCookie('token', user.token, { path: '/' });
          setCookie('username', user.username, { path: '/' }); 
          setCookie('role', user.role, { path: '/' });
          dispatch(saveUserDetails(user));
          dispatchResetApiRequestQQ()
          navigate('/dashboard');
        } else {
          toast.error('Invalid password. Please try again.');
          console.error('Login failed:', res);
        }
      })
      .catch((error) => {
        toast.error('Login failed. Please try again.');
        console.error('Login error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

    
  return (
    <div>
      {/* <LoginHeader /> */}
      <main className='logout  mt-0 ms-0' style={{ overflow: 'hidden' }}>
        <section>
          <div className='page-header min-vh-75'>
            <div className='container'>
              <div className='row'>
                <div className='col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto'>
                  <div className='card card-plain mt-4'>
                    <div className='card-header pb-0 text-left bg-transparent'>
                      <Link to=''>
                        <img
                          src='/assets/img/logo1.png'
                          style={{ maxWidth: '120px' }}
                          alt=''
                        />
                      </Link>
                      <h3 className='font-weight-bolder text-info text-gradient'>
                        Welcome back
                      </h3>
                      <p className='mb-0'>
                        Enter your username and password to sign in
                      </p>
                    </div>

                    <div className='card-body'>
                      <form role='form' onSubmit={handleSubmit(handleLogin)}>
                        <label>Username</label>
                        <div className='mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Username'
                            aria-label='Username'
                            aria-describedby='email-addon'
                            id='username'
                            name='username'
                            {...register('username', {
                              required: 'Username is required'
                            })}
                          />
                          <span>
                            <p className='text-danger error-msg'>
                              {errors.username && errors?.username?.message}
                            </p>
                          </span>
                        </div>
                        <label>Password</label>
                        <div className='mb-3 position-relative'>
                          <input
                            type={showPass === false ? 'password' : ''}
                            className='form-control position-relative pe-6'
                            placeholder='Password'
                            aria-label='Password'
                            aria-describedby='password-addon'
                            id='password'
                            name='password'
                            {...register('password', {
                              required: 'Password is required'
                            })}
                          />
                          <span>
                            <p className='text-danger error-msg'>
                              {errors?.password && errors?.password?.message}
                            </p>
                          </span>
                          {showPass === false ? (
                            <span
                              className='eye_icon'
                              style={{ cursor: 'pointer' }}
                              onClick={() => setShowPass(true)}
                            >
                              <i className='fa fa-eye'></i>
                            </span>
                          ) : (
                            <span
                              className='eye_icon'
                              style={{ cursor: 'pointer' }}
                              onClick={() => setShowPass(false)}
                            >
                              <i className='fa fa-eye-slash'></i>
                            </span>
                          )}
                        </div>
                        <div>
                        <div className='text-center'>
                          <button
                            type='submit'
                            className='btn bg-gradient-info w-100 mt-4 mb-0'
                          >
                            {loading ? <Loader /> : 'Sign In'}
                          </button>
                        </div>
                        </div>
                        <div>
                        <Link to='forget' className='mt-2'>
                        <p>Forgot
                        Password</p>
                        </Link>
                        </div>
                        <div>
                        <Link className='mt-2'>
                        <p> Create Account <Link to='/create'>Sin up</Link> </p>
                       
                        </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='oblique position-absolute top-0 h-100 d-md-block d-none me-n8'>
                    <div className='oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Loginfooter />
      </main>
    </div>
  )
}

export default Login
