import React, { useEffect, useState } from 'react'
import Loginfooter from '../../common/Loginfooter'
import { useForm } from 'react-hook-form'
import { PostDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router'
import Loader from '../../common/Loader'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchMmvData } from '../../store/actions/userActions'

const CreatePassword = () => {
  const dispatch = useDispatch()
  const loginUrl = 'https://api.insurefast.in/api/v1/'
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const cookie = useCookies()
  const [cookies, setCookie, removeCookie] = useCookies([
    'userName',
    'token',
    'role'
  ])
  useEffect(() => {
    dispatchMmvData('apiUrl', loginUrl)
  })

  const handleCreate = data => {
    setLoading(true)
    PostDataWithToken(loginUrl, 'admin/loginPanelCreate', data)
      .then(res => {
        console.log(res, 'datr')
        if (res && res.status === 'success') {
          const { token, username, role } = res.data
          setCookie('token', token, { path: '/' })
          setCookie('username', username, { path: '/' })
          setCookie(role, role, { path: '/' })
          navigate('/')
        } else {
          console.error('Login failed:', res)
        }
      })
      .catch(error => {
        console.error('Login error:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      {/* <LoginHeader /> */}
      <main className='logout  mt-0 ms-0' style={{ overflow: 'hidden' }}>
        <section>
          <div className='page-header min-vh-75'>
            <div className='container'>
              <div className='row'>
                <div className='col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto'>
                  <div className='card card-plain mt-4'>
                    <div className='card-header pb-0 text-left bg-transparent'>
                      <Link to=''>
                        <img
                          src='/assets/img/logo1.png'
                          style={{ maxWidth: '120px' }}
                          alt=''
                        />
                      </Link>
                      <h3 className='font-weight-bolder text-info text-gradient'>
                        Welcome back
                      </h3>
                      <p className='mb-0'>Enter your username to sign in</p>
                    </div>

                    <div className='card-body'>
                      <form role='form' onSubmit={handleSubmit(handleCreate)}>
                        <label>Username</label>
                        <div className='mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Username'
                            aria-label='Username'
                            aria-describedby='email-addon'
                            id='username'
                            name='username'
                            {...register('username', {
                              required: 'Username is required'
                            })}
                          />
                          <span>
                            <p className='text-danger error-msg'>
                              {errors.username && errors?.username?.message}
                            </p>
                          </span>
                        </div>
                        <label>Enter Role</label>
                        <div className='col-xl-12 col-lg-3 col-md-6 col-12'>
                          <label>Add Role</label>
                          <div className='mb-3'>
                            <select
                              className='form-control'
                              id='role'
                              name='role'
                              {...register('role', {
                                required: 'Role is required'
                              })}
                            >
                              <option key='' value=''>
                                Select Role
                              </option>
                              <option 
                              key='superAdmin' 
                              value='superAdmin'>
                                Super Admin
                              </option>
                              <option 
                              key='admin' 
                              value='admin'>
                                Admin
                              </option>
                              <option
                                key='mappedMananger'
                                value='mappedMananger'
                              >
                                Mapped Mananger
                              </option>
                            </select>
                          </div>
                          <span>
                            <p className='text-danger error-msg'>
                              {errors.role && errors?.role?.message}
                            </p>
                          </span>
                        </div>
                        <div>
                          <div className='text-center'>
                            <button
                              type='submit'
                              className='btn bg-gradient-info w-100 mt-4 mb-0'
                            >
                              {loading ? <Loader /> : 'Sign In'}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='oblique position-absolute top-0 h-100 d-md-block d-none me-n8'>
                    <div className='oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Loginfooter />
      </main>
    </div>
  )
}

export default CreatePassword
