import React from 'react'
import Layout from '../../common/Layout'

const Users = () => {
  return (
  <>
  <Layout></Layout>
  </>
  )
}

export default Users