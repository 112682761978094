
import {MMV_DATA, LOGGED_USER_DETAILS, RTO_DATA, RESET_MMV_DATA} from '../types'
const initialState = {
  userDetails: {
    Role : "",
  },
  mmvData: {
    apiUrl: '',
    vehicleType: '',
    partnerName: '',
    insurerName: '',
    insurerId:'',
    makeId: '',
    modelId: '',
    fuelType:'',
    varientId: '',
    insurerMmvId: '',
    action: '',
  },
  rtoData: {
    apiUrl: '',
    vehicleType: '',
    insurerName: '',
    state:"",
    city:"",
    rto:"",
    action: '',
  }
};
const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case MMV_DATA:
      const { key, value } = action.payload;
      let obj = { ...state.mmvData };
      if (key.includes(".")) {
        let reqKey = key.split(".");
        obj[reqKey[0]][reqKey[1]] = value;
      } else {
        obj[key] = value;
      }
      return {
        ...state,
        mmvData: obj,
      };
    case RTO_DATA:
      const { key1, value1 } = action.payload;
      let obj1 = { ...state.rtoData };
      if (key1.includes(".")) {
        let reqKey = key1.split(".");
        obj1[reqKey[0]][reqKey[1]] = value1;
      } else {
        obj1[key1] = value1;
      }
      return {
        ...state,
        rtoData: obj1,
      };
      case RESET_MMV_DATA:
      return {
        ...state,
        mmvData: action.payload.mmvData,
        rtoData: action.payload.rtoData,
      };
    default:
      return state;
  }
  
};
export default userReducers;

