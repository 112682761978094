import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useNavigate } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { MdArrowDropDown } from 'react-icons/md'
import { saveUserDetails } from '../store/actions/userActions'
import { useSelector } from 'react-redux'

const Header = ({ toggleSideNav, setToggleSideNav }) => {
  const handleSideBar = () => {
    console.log('toggleSideNav', toggleSideNav)
    setToggleSideNav(!toggleSideNav)
  }
  const navigate = useNavigate()
  const [modal, setModal] = useState(false)
  const toggleModal = () => setModal(!modal)
  const [cookies, setCookie, removeCookie] = useCookies(['token'])

  const cookie = useCookies()
  const Name = useSelector((state) => state?.root?.userDetails?.username);
  console.log(Name,"username");
  
  const handleLogout = () => {
    removeCookie('token', { path: '/' })
    navigate('/')
  }
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }
  return (
    <div>
      {/* <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg"> */}
      <nav
        className='navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl blur'
        id='navbarBlur'
        navbar-scroll='true'
      >
        <div className='container-fluid py-1 px-3'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5'>
              <li className='breadcrumb-item text-sm'>Pages</li>
              <li
                className='breadcrumb-item text-sm text-dark active'
                aria-current='page'
              >
                Dashboard
              </li>
            </ol>
            {/* <h6 className="font-weight-bolder mb-0">Dashboard</h6> */}
          </nav>
          <div
            className='collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-0'
            id='navbar'
          >
            <div className='ms-md-auto pe-md-3 d-flex align-items-center'>
              {/* <div className='input-group'>
                <span className='input-group-text text-body'>
                  <i className='fas fa-search' aria-hidden='true'></i>
                </span>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Type here...'
                />
              </div> */}
            </div>
            <ul className='navbar-nav justify-content-end'>
              <li className='nav-item d-xl-none ps-3 d-flex align-items-center'>
                <div
                  className='nav-link text-body p-0'
                  id='iconNavbarSidenav'
                  onClick={handleSideBar}
                >
                  <div className='sidenav-toggler-inner'>
                    <i className='sidenav-toggler-line'></i>
                    <i className='sidenav-toggler-line'></i>
                    <i className='sidenav-toggler-line'></i>
                  </div>
                </div>
              </li>
              <div className='profile-name' onClick={toggleDropdown}>
                <li>{Name}</li>
                <div className='vl-line'></div>
                <li className='nav-item dropdown d-flex align-items-center'>
                  <a
                    href='/'
                    className='nav-link text-body p-0'
                    id='dropdownMenuButton'
                    data-bs-toggle='dropdown'
                    aria-expanded={isDropdownOpen ? 'true' : 'false'}
                  >
                    <i className='fa fa-user' style={{color:"#2690d0"}}></i>
                  </a>
                  <ul
                    className={`dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4 ${
                      isDropdownOpen ? 'show' : ''
                    }`}
                    aria-labelledby='dropdownMenuButton'
                  >
                    {/* <li className='mb-2'>
                      <Link
                        to='/Profile'
                        className='dropdown-item border-radius-md'
                      >
                        <div className='d-flex py-1'>
                          <div className='my-auto'>
                            <img
                              src='../assets/img/team-2.jpg'
                              className='avatar avatar-sm me-3'
                              alt=''
                            />
                          </div>
                          <div className='d-flex flex-column justify-content-center'>
                            <h6 className='text-sm font-weight-normal mb-1'>
                              <span className='font-weight-bold'>Profile</span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </li> */}
                    <li>
                      <div
                        onClick={() => toggleModal()}
                        className='dropdown-item border-radius-md'
                      >
                        <div className='d-flex py-1'>
                          <div className='avatar avatar-sm bg-gradient-secondary me-3 my-auto'>
                            <svg
                              fill='none'
                              height='24'
                              viewBox='0 0 24 24'
                              width='24'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8'
                                stroke='#374151'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                              />
                            </svg>
                          </div>
                          <div className='d-flex flex-column justify-content-center'>
                            <h6 className='text-sm font-weight-normal mb-1'>
                              <span className='font-weight-bold'>Logout</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <MdArrowDropDown style={{ fontSize: '25px' }} />
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        centered
        className='logout_modal'
      >
        <ModalBody>
          <div className='close_btn' onClick={() => toggleModal()}>
            <i className='fa fa-close'></i>
          </div>
          <h3>Are you sure you want to Logout!</h3>
          <div className='modal_btn'>
            <button className='btn btn-danger' onClick={() => toggleModal()}>
              Cancel
            </button>
            <button className='btn btn-success' onClick={() => handleLogout()}>
              Confirm
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* </main> */}
    </div>
  )
}

export default Header
