import React from 'react'
import Layout from '../../common/Layout'

const Dashboard = () => {
;
  

  return (
    <Layout>
      {/* <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg"> */}
      {/* <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
            <div className='card dashboard_card'>
              <div className='card-body p-3'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='numbers'>
                      <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                        Total Employee
                      </p>
                    </div>
                  </div>
                  <div className='col-4 text-end'>
                    <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md"><i class="fa fa-user" aria-hidden="true"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
            <div className='card'>
              <div className='card-body p-3'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='numbers'>
                      <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                        Total Volunteer 
                      </p>
                    </div>
                  </div>
                  <div className='col-4 text-end'>
                    <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md"><i class="fa fa-calendar" aria-hidden="true"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
            <div className='card'>
              <div className='card-body p-3'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='numbers'>
                      <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                        total Visitor
                      </p>
                    </div>
                  </div>
                  <div className='col-4 text-end'>
                    <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md'>
                      <i
                        className='ni ni-paper-diploma text-lg opacity-10'
                        aria-hidden='true'
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
            <div className='card'>
              <div className='card-body p-3'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='numbers'>
                      <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                        total Ongoing Events
                      </p>
                    </div>
                  </div>
                  <div className='col-4 text-end'>
                    <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md'>
                      <i
                        className='ni ni-paper-diploma text-lg opacity-10'
                        aria-hidden='true'
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
            <div className='card'>
              <div className='card-body p-3'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='numbers'>
                      <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                        total Ongoing Events
                      </p>
                    </div>
                  </div>
                  <div className='col-4 text-end'>
                    <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md'>
                      <i
                        className='ni ni-paper-diploma text-lg opacity-10'
                        aria-hidden='true'
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <Footer /> */}
      {/* </main> */}
    </Layout>
  )
}

export default Dashboard
