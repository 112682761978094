import React, { useState } from "react";
import Header from "./Header";
// import Footer from "./Footer";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [toggleSideNav, setToggleSideNav] = useState(false);
  return (
    <div className="w-100" style={{ height: "100vh" }}>
      <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
        <Sidebar
          toggleSideNav={toggleSideNav}
          setToggleSideNav={setToggleSideNav}
        />
        <Header
          toggleSideNav={toggleSideNav}
          setToggleSideNav={setToggleSideNav}
        />
        {children}
        {/* <Footer /> */}
      </main>
    </div>
  );
};

export default Layout;
